import React from "react"
import { useCookies } from "react-cookie"

function ColorPicker(props) {
  const [cookies, setCookie] = useCookies(["themeColor"])

  const themeColors = [
    { hex: "#6b7280", label: "gray" },
    { hex: "#71717a", label: "zinc" },
    { hex: "#737373", label: "neutral" },
    { hex: "#78716c", label: "stone" },
    { hex: "#ef4444", label: "red" },
    { hex: "#f97316", label: "orange" },
    { hex: "#f59e0b", label: "amber" },
    { hex: "#eab308", label: "yellow" },
    { hex: "#84cc16", label: "lime" },
    { hex: "#22c55e", label: "green" },
    { hex: "#10b981", label: "emerald" },
    { hex: "#14b8a6", label: "teal" },
    { hex: "#06b6d4", label: "cyan" },
    { hex: "#0ea5e9", label: "sky" },
    { hex: "#3b82f6", label: "blue" },
    { hex: "#6366f1", label: "indigo" },
    { hex: "#8b5cf6", label: "violet" },
    { hex: "#a855f7", label: "purple" },
    { hex: "#d946ef", label: "fuchsia" },
    { hex: "#ec4899", label: "pink" },
    { hex: "#f43f5e", label: "rose" },
  ]

  const themeHandler = themeColor => {
    setCookie("themeColor", themeColor, { path: "/" })
  }

  return (
    <div
      role="menu"
      className="absolute right-0 z-30 w-64 p-6 translate-x-0 bg-white shadow-xl navigation_menu-dropdown left:auto top-full rounded-b-xl shadow-wuiSlate-500/10 ring-1 ring-inset ring-wuiSlate-200"
    >
      <ul role="none" className="grid w-full grid-cols-4 gap-4">
        {themeColors.map((color, index) => {
          return (
            <li
              className={`group relative col-span-1 flex h-6 w-full cursor-pointer items-center justify-center gap-2 [&>svg]:hidden ${
                color.label === cookies.themeColor
                  ? `after:absolute after:h-6 after:w-6 after:rounded-full after:ring-2 after:ring-offset-2  [&>svg]:!block after:ring-[${color.hex}]`
                  : ""
              } `}
              key={index}
              onClick={() => themeHandler(color.label)}
            >
              <span
                role="tooltip"
                className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-16 -translate-x-1/2 rounded bg-wuiSlate-500 p-2 text-center text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-wuiSlate-500 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
              >
                {color.label}
              </span>
              <span
                className={`bg-[${color.hex}] group relative block h-6 w-6  cursor-pointer overflow-hidden rounded-full hover:overflow-visible focus-visible:outline-none`}
              ></span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="white"
                className="absolute w-4 h-4 overflow-hidden"
              >
                <path
                  d="M10.283 18.7896L4.58301 13.0896L6.00801 11.6646L10.283 15.9396L19.458 6.76456L20.883 8.18956L10.283 18.7896Z"
                  fill="#fff"
                />
              </svg>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ColorPicker
