import React, { useEffect, useRef } from "react"
import lottie from "lottie-web"

function Lottie({ animationData }) {
  const container = useRef(null)

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
      resetSegments: 0,
    })

    // anim.addEventListener("complete", () => {
    //   anim.resetSegments(true)
    // })

    // return () => {
    //   lottie.destroy()
    // }
  }, [])

  return (
    <div
      ref={container}
      // onMouseEnter={() => {
      //   lottie.play()
      // }}
    />
  )
}

export default Lottie
